

   .phrase {
      font-size: 24px;
      font-weight: 700;
      font-style: oblique;
      letter-spacing:  5px ;
      text-transform:  uppercase ;
      color:  #27dcb4 
    }
    .imgCarousel {
      height: 55vh
    }
    .container {
      background-color: rgb(226, 243, 243);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .slide{
      max-width: 100%;
      height: auto;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }

    .slide.active{
      opacity: 1;
    }

    .whatsappIcon {
      font-size: 350%;
      color: #FFFFFF;
    }

    .buttonWhatsapp {
      background-color: #4fce5d;
      color: #FFFFFF;
      position: fixed;
      top: 87%;
      right: 2%;
      z-index: 20;
      border-radius: 50%;
    }
